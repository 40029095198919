import React from 'react';
import { Button, Flex, Text, Img } from '@chakra-ui/react';
import PropTypes from 'prop-types';
import { useTranslation } from 'next-i18next';
import MenuItem from '../../commonComponents/MenuItem';
import NextLink from '../NextLink';
import DefaultContainer from '../../layouts/DefaultContainer';
import { size } from '../../theme/constants';

const FooterDesktop = ({ linksToImg, email, navigationItems }) => {
  const { t } = useTranslation(['common']);

  return (
    <DefaultContainer
      bg='black.500'
      pb={5}
      h={size.footer.desktop}
      flexDirection='row'
      boxSizing='border-box'
      justifyContent='space-between'
      container={{
        as: 'footer',
      }}
    >
      <Flex
        w='33%'
        flexGrow='1'
        mt={3}
        flexDirection='column'
        alignItems='center'
        justifyContent='center'
      >
        <Flex alignItems='center'>
          {linksToImg?.map((item, index) => (
            <NextLink href={item.url} key={index} target='_blank'>
              <Img
                alt={item.alt}
                mr={index === linksToImg?.length - 1 ? 0 : 7}
                src={item.img}
              />
            </NextLink>
          ))}
        </Flex>
      </Flex>
      <Flex
        w='33%'
        flexGrow='1'
        wrap='wrap'
        justifyContent='center'
        alignContent='space-around'
        data-testid='footerNavigation'
      >
        {navigationItems.map(({ name, to }, index) => (
          <MenuItem
            key={index}
            to={to}
            value={t(`menu.${name}`)}
            textAlign='center'
            mx={2}
            h='min'
            activeColor='white'
            _hover={{ color: 'white' }}
            dataTestId={`footerMenuLink-${name}`}
          />
        ))}
      </Flex>
      <Flex
        w='33%'
        flexGrow='1'
        flexDirection='column'
        justifyContent='space-between'
      >
        <Flex flexDirection='column'>
          <NextLink style={{ textAlign: 'center' }} href={`mailto:${email}`}>
            <Text
              as='span'
              color='typography.secondary'
              fontWeight='semibold'
              fontSize={['xl', 'xl', 'xl', '2xl']}
            >
              {email}
            </Text>
          </NextLink>
          <Text
            as='span'
            color='typography.secondary'
            fontWeight='medium'
            textAlign='center'
            fontSize={['sm', 'sm', 'sm', 'md']}
            mt={2.5}
          >
            {t('you-can-contact-us-by-email')}
          </Text>
        </Flex>
        <NextLink href='/become-performer'>
          <Button
            variant='secondaryBlack'
            width='100%'
            borderColor='typography.secondary'
            color='typography.secondary'
            _hover={{ borderColor: 'white', color: 'white' }}
          >
            {t('button.how-to-become-a-executor')}
          </Button>
        </NextLink>
      </Flex>
    </DefaultContainer>
  );
};

FooterDesktop.propTypes = {
  email: PropTypes.string,
  locations: PropTypes.array,
  linksToImg: PropTypes.array,
  navigationItems: PropTypes.array,
};
export default FooterDesktop;
